<template>
  <div>
    <div class="location">开放接口</div>
    <div class="locations">
      丰富的解决方案为您的业务助力
    </div>
    <div class="tab">
      <van-tabs v-model="active" type="card" background="#F1ECEC">
        <van-tab title="Java SDK">
          <div class="tab_box">
            <p>敬请留意</p>
          </div>
        </van-tab>
        <van-tab title="PythonSDK">
          <div class="tab_box">
            <p>敬请留意</p>
          </div>
        </van-tab>
        <van-tab title="PHP SDK">
          <div class="tab_box">
            <p>敬请留意</p>
          </div>
        </van-tab>
        <van-tab title="IOS SDK">
          <div class="tab_box">
            <p>敬请留意</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "SDK开发包"
}
</script>

<style scoped>
.location {
  margin-top: 5%;
  font-size: 26px;
  text-align: center;
}

.locations {
  font-size: 12px;
  margin: auto;
  text-align: center;
  height:5vh;
}

.van-tabs {
  padding-top: 5%;
  height: 160px;
}

.tab {
  border-radius: 10px;
  margin: auto;
  width: 90%;
  height:160px;
  background-color: #F5F5F5;
  margin-bottom: 30px;

}

.tab_box {
  color: #B9B9B9;
  font-size: 13px;
  padding: 5%;
}
</style>